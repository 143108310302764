import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const OpenSource = () => (
  <Layout>
    <SEO title="Guillaume Bouffard | Open source" />
    Open source (NOT READY YET)
    <br />
    Repo name + click that redirects to github repo
    <br />
    number of contributions
    <br />
    number of stars
    <br />
  </Layout>
)

export default OpenSource
